import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import React, {Component} from "react";
import Fade from 'react-reveal/Pulse'
import Jump from 'react-reveal/Jump';
import {WorkExpierence} from "./WorkExpierence";

export class AboutEducation extends Component {

    render() {
        return (
            <div className="col-sm-6 pt-5 mt-4">
                <div className="row justify-content-center">
                    <h2 className="font-weight-light">Education</h2>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="lineBottom"/>

                        <div className="row mts-5 border-bottom-edu mobile-padding-left">
                            <Fade delay={1000}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2018</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom big delay={1000}>
                                <div className="col-sm-8">Masters degree, IT Project managment, Liepāja University
                                </div>
                            </Fade>
                        </div>

                        <div className="row mts-5 border-bottom-edu mobile-padding-left">
                            <Fade delay={1200}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2013</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom big delay={1200}>
                                <div className="col-sm-8">Bachelor, Computer science, Liepāja University
                                </div>
                            </Fade>
                        </div>

                        <div className="row mts-5 border-bottom-edu mobile-padding-left">
                            <Fade delay={1400}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2010</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom big delay={1400}>
                                <div className="col-sm-8">Graduated High School, Mathematical category, Limbaži
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <WorkExpierence/>
                </div>
            </div>

        );
    }

}

