import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import React, {Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Fade from 'react-reveal/Fade';

export interface IState {
    seconds: number,
}


export class KnowledgeBaseContainer extends Component<{}, IState> {

    public interval: any;

    constructor(props: IState) {
        super(props);
        this.state = {seconds: 0};
    }

    public tick() {
        this.setState(state => ({
            seconds: state.seconds + 1
        }));
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1500); //Math.floor(Math.random() * 800)+300);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className="row blueColor pt-4 pb-4">
                <div className="container pb-5 mb-5 mobile-padding-left">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className="font-weight-light navColor pb-5 mt-5">Knowledge base</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row">
                            
                            <div className="col-lg-4">
                                    <div className="text-align-center">
                                        <Fade delay={1600}>
                                            <div className="rounded-circle bubble-knowledge mobile-square">
                                                <FontAwesomeIcon icon={['fab', 'android']}
                                                                 className={this.state.seconds % 6 === 0 ? 'sizeLarger navColor insideBubble' : 'sizeLarger navColor insideBubble addedScaling'}/>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="text-align-center">
                                        <Fade delay={1000}>
                                            <div className="rounded-circle bubble-knowledge mobile-square">
                                                <FontAwesomeIcon icon={['fab', 'github']}
                                                                 className={this.state.seconds % 6 === 4 ? 'sizeLarger navColor insideBubble' : 'sizeLarger navColor insideBubble addedScaling'}/>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="text-align-center">
                                        <Fade delay={1400}>
                                            <div className="rounded-circle bubble-knowledge mobile-square">
                                                <FontAwesomeIcon icon={['fab', 'php']}
                                                                 className={this.state.seconds % 6 === 3 ? 'sizeLarger navColor insideBubble' : 'sizeLarger navColor insideBubble addedScaling'}/>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">

                            <div className="col-lg-4">
                                    <div className="text-align-center">
                                        <Fade delay={1000}>
                                            <div className="rounded-circle bubble-knowledge mobile-square">
                                                <FontAwesomeIcon icon={['fab', 'js-square']}
                                                                 className={this.state.seconds % 6 === 4 ? 'sizeLarger navColor insideBubble' : 'sizeLarger navColor insideBubble addedScaling'}/>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>

                                
                                <div className="col-lg-4">
                                    <div className="text-align-center">
                                        <Fade delay={1500}>
                                            <div className="rounded-circle bubble-knowledge mobile-square">
                                                <FontAwesomeIcon icon={['fab', 'node']}
                                                                 className={this.state.seconds % 6 === 5 ? 'sizeLarger navColor insideBubble' : 'sizeLarger navColor insideBubble addedScaling'}/>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4">
                                    <div className="text-align-center">
                                        <Fade delay={1300}>
                                            <div className="rounded-circle bubble-knowledge mobile-square">
                                                <FontAwesomeIcon icon={['fab', 'html5']}
                                                                 className={this.state.seconds % 6 === 1 ? 'sizeLarger navColor insideBubble' : 'sizeLarger navColor insideBubble addedScaling'}/>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}