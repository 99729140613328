import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import React, {Component} from "react";
import whiteLogo from '../resources/logo_white.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Fade from 'react-reveal/Fade';

export interface IState {
    seconds: number,
}


export class Footer extends Component<{}, IState> {

    public interval: any;

    constructor(props: IState) {
        super(props);
        this.state = {seconds: 0};
    }

    public tick() {
        this.setState(state => ({
            seconds: state.seconds + 1
        }));
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1500); //Math.floor(Math.random() * 800)+300);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className="row blueColor">
                <div className="container  mobile-padding-left">
                    <div className="row justify-content-center pt-5 pb-5">
                        <a href="mailto:martins@sinka.lv"><img src={whiteLogo} width="70" className="d-inline-block align-top" alt=""/></a>
                    </div>
                </div>
            </div>
        );
    }
}