import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import myImage from '../resources/image-me.jpg'
import React, {Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


export class AboutMe extends Component {

    render() {
        return (
            <div className="col-sm-6">
                <div className="row justify-content-center">
                    <h1 className="align-content-center font-weight-light rem4 mb-0 titleColor">Martins Sinka</h1>
                </div>
                <div className="row justify-content-center">
                    <h2 className="font-weight-light rem3 textColor">Software Developer</h2>
                </div>
                <div className="row justify-content-center pb-5">
                    <a className="noUnderline linkColor" href="mailto:martins@sinka.lv"><h3
                        className="noUnderline">martins@sinka.lv</h3></a>
                </div>
                <div className="row justify-content-center">
                    <img src={myImage} className="myImage"/>
                </div>
                        <div className="row justify-content-center mt-3">
                            <div className="pr-1"><a
                                href="https://www.linkedin.com/in/martins-sinka-832421b5/" target="_blank"
                                className="noUnderline"><FontAwesomeIcon icon={['fab', 'linkedin']}
                                                                         className="sizeMedium linkColor hoverAccent"/></a>
                            </div>
                            <div className="pr-1"><a href="https://www.facebook.com/silverstay"
                                                                    target="_blank"
                                                                    className="noUnderline"><FontAwesomeIcon
                                icon={['fab', 'facebook-square']} className="sizeMedium linkColor hoverAccent"/></a>
                            </div>
                            <div className="pr-1"><a href="https://twitter.com/Silverstay"
                                                                    target="_blank"
                                                                    className="noUnderline"><FontAwesomeIcon
                                icon={['fab', 'twitter-square']} className="sizeMedium linkColor hoverAccent"/></a>
                            </div>
                            <div className="pr-1"><a href="https://www.instagram.com/silverstay/"
                                                                    target="_blank"
                                                                    className="noUnderline"><FontAwesomeIcon
                                icon={['fab', 'instagram']} className="sizeMedium linkColor hoverAccent"/></a></div>
                        </div>
                    </div>
        );
    }

}

