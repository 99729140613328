import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import React,{Component} from "react";
import {AboutMe} from '../components/AboutMe'
import {AboutEducation} from "./AboutEducation";

export class AboutMeContainer extends Component{

    render(){
        return(
            <div>
                <div className="container">
                    <div className="row pt-5 mb-5 pb-5">
                            <div className="row">
                                <AboutMe/>
                                <AboutEducation/>
                            </div>
                    </div>
                </div>
            </div>
        );
    }

}

