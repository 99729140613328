import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import React, {Component} from "react";
import Fade from 'react-reveal/Pulse'

export class WorkExpierence extends Component {

    render() {
        return (
            <div className="col-sm-12 mt-5 pts-5">
                <div className="row justify-content-center">
                    <h2 className="font-weight-light">Work experience</h2>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="lineBottom"/>

                        <div className="row mts-5 mobile-padding-left">
                            <Fade delay={800}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2020</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom small delay={800}>
                                <div className="col-sm-8"><b>2020 - <i>Present</i></b>. Android software developer, SPARQ
                                </div>
                            </Fade>
                        </div>

                        <div className="row mts-5 mobile-padding-left">
                            <Fade delay={800}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2019</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom small delay={800}>
                                <div className="col-sm-8"><b>2019 - <i>2020</i></b>. Android software developer, ThinkitiveLab
                                </div>
                            </Fade>
                        </div>

                        <div className="row mts-5 mobile-padding-left">
                            <Fade delay={1000}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2017</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom big delay={1000}>
                                <div className="col-sm-8"><b>2017 - <i>2019</i></b>. QA Engineer, YOPA Property
                                </div>
                            </Fade>
                        </div>


                        <div className="row mts-5 mobile-padding-left">
                            <Fade delay={1200}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2016</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom big delay={1200}>
                                <div className="col-sm-8"><b>2016 - <i>2016</i></b> QA Engineer, Giraffe360
                                </div>
                            </Fade>
                        </div>

                        <div className="row mts-5 mobile-padding-left">
                            <Fade delay={1400}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2013</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom big delay={1400}>
                                <div className="col-sm-8"><b>2013 - <i>Present</i></b>. Lecturer, Liepāja University
                                </div>
                            </Fade>
                        </div>

                        <div className="row mts-5 mobile-padding-left">
                            <Fade delay={1500}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2013</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom big delay={1500}>
                                <div className="col-sm-8"><b>2013 - <i>2016</i></b>. Android software developer, Webpage developer , IT-Lideris
                                </div>
                            </Fade>
                        </div>

                        <div className="row mts-5 mobile-padding-left">
                            <Fade delay={1550}>
                                <div className="col-sm-2"><span
                                    className="dateOfEducation titleColor font-weight-light">2012</span></div>
                            </Fade>
                            <div className="col-sm-2">
                                <div className="RoundCircle">
                                    <div className="innerCircle"></div>
                                </div>
                            </div>
                            <Fade bottom big delay={1550}>
                                <div className="col-sm-8"><b>2012 - 2013.</b> Android software developer, SIA 'Brain solutions'
                                </div>
                            </Fade>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

