import React, { Component } from 'react';
import logo from './logo.svg';
import logoSinka from './sinka_logo.svg'
import {Header} from './components/Header';
import {MainPicture} from './components/MainPicture';
import {AboutMeContainer} from './components/AboutMeContainer'
import {TutorialContainer} from './components/tutorialContainer';
import {KnowledgeBaseContainer} from "./components/knowledgeBaseContainer";
import {Portfolio} from "./components/Portfolio";
import {Footer} from "./components/Footer"
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import './App.css';

class App extends Component {
  array:any ={something:1,something2:2};

  render() {
    return (
        <div>
            <Header/>
            <Element name="home">
              <MainPicture/>
            </Element>
            <Element name="aboutMe">
              <AboutMeContainer/>
            </Element>
            <KnowledgeBaseContainer/>
            <Element name="portfolio">
              <Portfolio/>
            </Element>
           {/*  <TutorialContainer/> */}
            <Footer/>
        </div>
    );
  }
}

export default App;
