import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faIgloo } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagram, faTwitterSquare, faLinkedin, faGit } from "@fortawesome/free-brands-svg-icons"
import { faReact, faNode, faPhp, faHtml5, faJsSquare, faAndroid, faGithub} from "@fortawesome/free-brands-svg-icons/"


library.add(faIgloo, faFacebookSquare,faInstagram,faTwitterSquare,faLinkedin,faReact, faNode, faPhp, faHtml5,faJsSquare,faAndroid, faGithub);
ReactDOM.render(<App/>,document.getElementById('root'));
//ReactDOM.render(<App />, document.getElementById('header'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
