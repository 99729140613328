
import whiteLogo from '../resources/logo_white.svg';
import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import React,{Component} from "react";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
let navBar = "navBar";
interface IMyComponentState {
    scroll:any,
    top:any,
    height:any,
    toggle:boolean
}
export class Header extends Component<{}, IMyComponentState>{

    constructor(props:any) {
        super(props);
        this.state = {
            scroll:null,
            top:null,
            height:null,
            toggle:false
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleNavBar = this.toggleNavBar.bind(this)
    }

    handleScroll() {
        this.setState({scroll: window.scrollY});
    }

    componentDidMount() {
        let el = document.getElementById("navBar");
        // @ts-ignore
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
       // this.state.scroll > this.state.top ?

    }

    toggleNavBar(){
       // let el = document.getElementById("navBartoggle");
        this.setState({toggle: !this.state.toggle});
    }


    render(){
        return(
            <nav className={this.state.scroll > this.state.top ? "navbar navbar-expand-lg navbar-dark fixed-top transition-scroll-down" : "navbar navbar-expand-lg navbar-light fixed-top"} id={navBar}>
                <Link  activeClass="active" to="home" spy={true} smooth={true} offset={50} duration={500} delay={50}><a className="navbar-brand" href="#">
                    <img src={whiteLogo} width="100" className="d-inline-block align-top" alt=""/>
                </a></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" id="navBartoggle" onClick={ this.toggleNavBar}
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={this.state.toggle?  "collapse navbar-collapse show" :  "collapse navbar-collapse"}  id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                        <Link  activeClass="active" to="aboutMe" spy={true} smooth={true} offset={50} duration={500} delay={50}><a className="nav-link navColor" href="#">ABOUT ME</a></Link>
                        </li>
                        <li className="nav-item">
                        <Link  activeClass="active" to="portfolio" spy={true} smooth={true} offset={50} duration={500} delay={50}><a className="nav-link navColor" href="#">PORTFOLIO</a></Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }

}
export default Header;
