import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import React,{Component} from "react";


export class MainPicture extends Component{

    render(){
        return(
           <div className="big-picturecontainer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11">
                                
                        </div>
                    </div>
                </div>
           </div>
        );
    }

}

