import 'bootstrap/dist/css/bootstrap.css';
import {Button, Modal} from 'react-bootstrap';
import '../App.css';
import React, {Component, useState} from "react";
import rindas from '../resources/pii1.jpg'
import rindas2 from '../resources/pii2.jpg'
import rindas3 from '../resources/pii3.jpg'
import taro1 from '../resources/taro1.jpg'
import taro2 from '../resources/taro2.jpg'
import taro3 from '../resources/taro3.jpg'
import washerly1 from '../resources/wash1.jpg'
import washerly2 from '../resources/wash2.jpg'
import washerly3 from '../resources/wash3.jpg'
import li1 from '../resources/li1.jpg'
import li2 from '../resources/li2.jpg'
import li3 from '../resources/li3.jpg'
import sparq from '../resources/sparq1.jpg'
import sparq2 from '../resources/sparq2.jpg'
import sparq3 from '../resources/sparq3.jpg'
import phoneFrame from '../resources/frame-phone.png'

import lii_icon from '../resources/lii_icon.png'
import pii_icon from '../resources/pii_icon.png'
import tarro_icon from '../resources/tarro_icon.png'
import washerly_icon from '../resources/washerly_icon.png'
import sparq_icon from '../resources/sparq_icon.png'

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Fade from 'react-reveal/Fade'



export class Portfolio extends Component {

 
    PiiBernuRindas() {
        const [show, setShow] = useState(false);
      
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
      
        return (
          <>
            <img src={pii_icon} className="icon_modal" onClick={handleShow}/>
        
            <Modal 
            show={show} 
            onHide={handleClose}
            size="xl"
            
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="row pl-1 pr-2 pt-1 pb-1">
              <div className="col-sm-12 col-md-3 pr-0 justify-content-center">
                            <img src={phoneFrame} className="overlay_phone"/>
                            <CarouselProvider
                                className="example"
                                naturalSlideWidth={253}
                                naturalSlideHeight={548}
                                interval={3000}
                                totalSlides={3}
                                touchEnabled={false}
                                dragEnabled= {false}
                                isPlaying={true}
                            >
                            <Slider>
                                <Slide index={0}><img src={rindas} className="phone_size"/></Slide>
                                <Slide index={1}><img src={rindas2} className="phone_size"/></Slide>
                                <Slide index={2}><img src={rindas3} className="phone_size"/></Slide>
                            </Slider>
                        </CarouselProvider>
                        </div>
                        <div className="col-sm-1 col-md-1"></div>
                        <div className="col-sm-12 col-md-8">
                        <h4>PII Bērnu rindas (Kindergartens queue app)</h4><p>Published application (Not available anymore)<br/> Created in 2013<br/><br/>  It allowed parents to view what queue they were in specific kindergarten they applied. Once a week they would get notification whether they have been moved up into queue. It also had information about kindergarten. Uses Application itself was written in <b>JAVA</b> and uses <b>JSON</b> to fetch data from <b>PHP</b> server.</p>
                        </div>
              </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </>
        );
      }


      LiepajasIzaicinajums() {
        const [show, setShow] = useState(false);
      
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
      
        return (
          <>
           <img src={lii_icon} className="icon_modal" onClick={handleShow}/>
            <Modal 
            show={show} 
            onHide={handleClose}
            size="xl"
            
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="row pl-1 pr-2 pt-1 pb-1">
              <div className="col-sm-12 col-md-3 pr-0 justify-content-center">
                            <img src={phoneFrame} className="overlay_phone"/>
                            <CarouselProvider
                                className="example"
                                naturalSlideWidth={253}
                                naturalSlideHeight={548}
                                interval={3000}
                                totalSlides={3}
                                touchEnabled={false}
                                dragEnabled= {false}
                                isPlaying={true}
                            >
                            <Slider>
                            <Slide index={1}><img src={li1} className="phone_size"/></Slide>
                                <Slide index={2}><img src={li3} className="phone_size"/></Slide>
                                <Slide index={3}><img src={li2} className="phone_size"/></Slide>
                            </Slider>
                        </CarouselProvider>
                        </div>
                        <div className="col-sm-1 col-md-1"></div>
                        <div className="col-sm-12 col-md-8">
                        <h4>Liepajas izaicinājums (Liepaja challenge)</h4><p>Published application (Not available anymore)<br/>Created in 2015<br/><br/> Created together with local teen organization allowed people to view specific viewpoints in Liepaja city, and make a picture if viewpoint is within given range. Then upload it to server in a webpage where it was widely available for other to interact.Application was written in  <b>JAVA</b> and used  <b>JSON</b> to fetch data from <b>PHP</b> server.  It also used <b>Google maps</b> with custom markers, range finder. Application used camera to take a selfie and upload it to <b>PHP</b> server.   </p>
                          </div>
              </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </>
        );
      }

      TarroCard() {
        const [show, setShow] = useState(false);
      
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
      
        return (
          <>
           <img src={tarro_icon} className="icon_modal" onClick={handleShow}/>
            <Modal 
            show={show} 
            onHide={handleClose}
            size="xl"
            
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="row pl-1 pr-2 pt-1 pb-1">
              <div className="col-sm-12 col-md-3 pr-0 justify-content-center">
                            <img src={phoneFrame} className="overlay_phone"/>
                            <CarouselProvider
                                className="example"
                                naturalSlideWidth={253}
                                naturalSlideHeight={548}
                                interval={3000}
                                totalSlides={3}
                                touchEnabled={false}
                                dragEnabled= {false}
                                isPlaying={true}
                            >
                            <Slider>
                                <Slide index={1}><img src={taro3} className="phone_size"/></Slide>
                                <Slide index={2}><img src={taro2} className="phone_size"/></Slide>
                                <Slide index={3}><img src={taro1} className="phone_size"/></Slide>
                            </Slider>
                        </CarouselProvider>
                        </div>
                        <div className="col-sm-1 col-md-1"></div>
                        <div className="col-sm-12 col-md-8">
                        <h4>Tarro card app</h4><p>Published status: Unknown<br/> Created in 2017<br/><br/>Application allowed user to view Tarro cards. Draw random cards and read given description to specific card. Application itself was written in <b>Cordova</b> (Typescript). Used <b>Firebase</b> as database. After it was finished code was given to client. As of this moment i have no idea if application was ever published. </p>
                          </div>
              </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </>
        );
      }

      Washerly() {
        const [show, setShow] = useState(false);
      
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
      
        return (
          <>
        <img src={washerly_icon} className="icon_modal" onClick={handleShow}/>
      
            <Modal 
            show={show} 
            onHide={handleClose}
            size="xl"
            
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="row pl-1 pr-2 pt-1 pb-1">
              <div className="col-sm-12 col-md-3 pr-0 justify-content-center">
                            <img src={phoneFrame} className="overlay_phone"/>
                            <CarouselProvider
                                className="example"
                                naturalSlideWidth={253}
                                naturalSlideHeight={548}
                                interval={3000}
                                totalSlides={3}
                                touchEnabled={false}
                                dragEnabled= {false}
                                isPlaying={true}
                            >
                            <Slider>
                           
                            <Slide index={1}><img src={washerly2} className="phone_size"/></Slide>
                                <Slide index={2}><img src={washerly1} className="phone_size"/></Slide>
                                <Slide index={3}><img src={washerly3} className="phone_size"/></Slide>
                            </Slider>
                        </CarouselProvider>
                        </div>
                        <div className="col-sm-1 col-md-1"></div>
                        <div className="col-sm-12 col-md-8">
                        <h4>Washerly</h4><p>Published application (Limited country access) <br/> Created in 2019<br/><br/>Working primarily in  Kotlin, but some of the code was developed in Java. While working on this application, i have made always-on GPS service, for employee tracking. Added payment system with stripe. Developed SocketIO for real-time data transfer. Added multi language feature. Created multiple fragment views with REST. Created custom RecyclerViews that allows client to see statistical data. 
Working daily with ConstraintLayouts and FragmentViews. MVVM is daily routine and part of my development routine. Using multiple different libraries for rapid development. 
<br/>
                                                Uses 
                                                <ul>
                                                    <li><b>Google maps</b></li>
                                                    <li><b>Stripe</b> payments</li>
                                                    <li><b>Firebase</b> for notifications</li>
                                                    <li><b>Coroutines</b> for async data download</li>
                                                    <li><b>Retrofit</b> for REST</li>
                                                    <li><b>Dagger</b> for dependency injection</li>
                                                    <li><b>RoomDB</b> for inapp database</li>
                                                    <li><b>Lifecycle</b> for view-models and LiveData</li>
                                                    <li><b>SocketIO</b> for real-time data transfer and view updates</li>
                                                    <li><b>Lottie</b> for animations</li>
                                                    <li><b>Node</b> for backend</li>
                                                    </ul> 
                                                    </p>
                          </div>
              </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </>
        );
      }


      SparqApp() {
        const [show, setShow] = useState(false);
      
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
      
        return (
          <>
            <img src={sparq_icon} className="icon_modal" onClick={handleShow}/>
        
            <Modal 
            show={show} 
            onHide={handleClose}
            size="xl"
            
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="row pl-1 pr-2 pt-1 pb-1">
              <div className="col-sm-12 col-md-3 pr-0 justify-content-center">
                            <img src={phoneFrame} className="overlay_phone"/>
                            <CarouselProvider
                                className="example"
                                naturalSlideWidth={253}
                                naturalSlideHeight={548}
                                interval={3000}
                                totalSlides={3}
                                touchEnabled={false}
                                dragEnabled= {false}
                                isPlaying={true}
                            >
                            <Slider>
                                <Slide index={0}><img src={sparq} className="phone_size"/></Slide>
                                <Slide index={1}><img src={sparq2} className="phone_size"/></Slide>
                                <Slide index={2}><img src={sparq3} className="phone_size"/></Slide>
                            </Slider>
                        </CarouselProvider>
                        </div>
                        <div className="col-sm-1 col-md-1"></div>
                        <div className="col-sm-12 col-md-8">
                        <h4>SPARQ - Personal Finance Platform</h4><p>Published application, <i>still in development</i> (available in most countries)<br/> Created in 2021<br/><br/> Application is neo banking style application, that allows users, to manage money. Order card - make and receive payments. Gamification elements also were added. Created mostly in  Kotlin. Minor code was in Java. Application was created singlehandedly. Dagger 2 dependency injection. Made MVVM framework, with activity to fragment design. Biometric login. Used multiple libaries for faster development time. 
                        Uses 
                                                <ul>
                                                    <li><b>Google maps</b></li>
                                                    <li><b>Firebase</b> for notifications</li>
                                                    <li><b>Coroutines</b> for async data download</li>
                                                    <li><b>Retrofit</b> for REST</li>
                                                    <li><b>Zendesk for technical support</b></li>
                                                    <li><b>Dagger 2</b> for dependency injection</li>
                                                    <li><b>Lifecycle</b> for view-models and LiveData</li>
                                                    <li><b>Lottie</b> for animations</li>
                                                    <li><b>Java</b> for backend</li>
                                                    <li><b>Elcoin</b> for banking systems</li>
                                                    </ul> </p>
                        </div>
              </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </>
        );
      }
    
    render() {
        return (
            <div className="row pt-5 pb-5">
                <div className="container mobile-padding-left">
                    <div className="row">
                        <div className="col-sm-12">
                        <h1 className="font-weight-light mainColor pb-5 mb-4">Portfolio</h1>
                        </div>
                    </div>
                    <div className="row pb-5 text-align-center">
                      <div className="col-sm-1"></div>
                    <div className="col-sm-2">
                      <div className="row">
                          <div className="col-lg-6 pt-3 pb-3"><this.PiiBernuRindas/></div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="row">
                          <div className="col-lg-6 pt-3 pb-3"><this.LiepajasIzaicinajums/></div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="row">
                          <div className="col-lg-6 pt-3 pb-3"> <this.TarroCard/></div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="row">
                          <div className="col-lg-6 pt-3 pb-3"><this.Washerly/></div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="row">
                          <div className="col-lg-6 pt-3 pb-3"> <this.SparqApp/></div>
                      </div>
                    <div className="col-sm-1"></div>
                    </div>
                  
                 
                   
                    

                    </div>
                </div>
            </div>
        );
    }

}

